const INFO = {
	main: {
		title: "Kobe",
		name: "Kobe Brian Santos",
		email: "kobebrian.santos.e@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/kobekoblanca",
		github: "https://github.com/kobekobe0",
		linkedin: "https://www.linkedin.com/in/kobesantos/",
		instagram: "https://www.instagram.com/kobekoblanca/",
		gitlab: "https://gitlab.com/kobekoblanca",
		facebook: "https://www.facebook.com/KobeSantos24",
	},

	homepage: {
		title: "Full-stack web developer, and a chess bum.",
		description:
			"I am a Full-stack web developer with expertise in MERN stack. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "Kobe, a full-stack web developer.",
		description:
			"Kobe Brian Santos, 20-year-old currently a working 3rd year college student based in the Philippines. I'm a web developer and a self-taught programmer. I'm currently working as a full stack developer, I mainly use MERN stack when developing web apps. The thing I love most about coding is that there’s always something new to learn so it’s impossible to get bored. There’s so many ways to be creative and so many different problems to solve and I thrive on the challenge. I am currently looking for frontend, backend, or full-stack web development roles.",
	},

	articles: {
		title: "Navigating the Abyss: My Twisted Code Thoughts Unveiled to Elicit Terror (or a Spark of Inspiration) in the Next Gen of Innovators",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, college, and more.",
	},

	projects: [
		{
			title: "Project 1",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description:
				"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
